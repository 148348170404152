import React, { useEffect, useState } from 'react';
//  import imageHero from './images/hero.png'
import qs from 'query-string';
import axios from 'axios';

import airFit from './images/AirFit.png';

const BACKENDAUTHORIZESERVER = process.env.REACT_APP_SQUARE_AUTH;

export default function App() {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const authorizeTrainerForSquare = async () => {
      try {
        const parsed = qs.parse(window.location.search);
        // code contains authorization for 5 min
        const { code, state } = parsed;

        // send this code to our server and authenticate with stripe
        console.log(code, ' this is the code in telepractice app');
        const url = BACKENDAUTHORIZESERVER;
        const data = JSON.stringify({
          code,
          sub: state,
        });


        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const result = await axios.post(url, data, config);
        // store account id and refresh token for later use
        // get provider licensed states, authorization, title,
        //  use accountId info to authenticate for under header: Square-Account: accountId
        const parsedResult = result.data;

        console.log(parsedResult, ' this is the parsed result');

        const { input } = parsedResult;
        console.log(input, ' this is input');
        if (input) {
          setSuccess(true);
        }
      } catch (e) {
        const { data } = e.response;
        //  setIsLoading(false);
        console.log(e.response, ' this is error');
        setError(`Oops something went wrong, ${data.message}. Please try again.`);
      } finally {
        setIsLoading(false);
      }
    };

    authorizeTrainerForSquare();
  }, []);

  return (
    <div className="">
      <nav
        className="dt w-100 pa2"
        style={{ backgroundColor: '#f96d00' }}
      >
        <a
          href="https://www.airfit.live"
          rel="noreferrer noopener"
          target="_blank"
          className="dtc v-mid white link dim w-25"
        >
          <img alt="airfit-logo" style={{ height: '25%', width: '15%' }} className="fl" src={airFit} />
        </a>
      </nav>
      <div className="dt w-100 vh-50 mw6-ns center tc">
        { isLoading
          ? (
            <h1 className="dtc v-mid f2-l f3 fw3 ma2">
              Loading ...
            </h1>
          )
          : (
            <div className="dtc v-mid">
              {success && (
                <div>
                  <p className="f3 fw3 ma3">
                    Your account is successfully authorized to accept payments!
                  </p>
                </div>
              )}
              {!success && (
                <div>
                  <p className="f3 fw3 ma3">
                    Your account is not authorized to accept payments. Please go back and try again.
                  </p>
                </div>
              )}
              {error && (
                <p className="f5 fw5 red i ma3">
                  {error}
                </p>
              )}
            </div>
          )}
      </div>
    </div>
  );
}
